@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;900&family=Montserrat:wght@300;400&display=swap');
// Google Font Reference(s)
$font-merriweather: 'Merriweather', serif;
$font-montserrat: 'Montserrat', sans-serif;

// Our variables
$base-font-family: $font-montserrat;
$base-font-size:   1.8rem;
$base-font-weight: 400;

$font-heading: $font-merriweather;

$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$text-color:       	#333;
$background-color: 	white;
$brand-color:      	#442C79; // Purple
$brand-color-alt:	#84BF41; // Green

// $on-palm:          600px;
// $on-laptop:        800px;

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}


// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "_normalise",
        "_resets-helpers",
        "_global"
;

a {
	color: #333;
	text-decoration: none;
	border-bottom: 1px solid $brand-color-alt;
	transition: all 0.3s;

	&:hover {
		color: black;
		border-bottom-color: darken($brand-color-alt, 10%);
	}
}

section {
	padding: 4.2rem 0 3.2rem 0;
}

h1,
h2,
h3,
h4 {
	color: $brand-color;
}

.page-content {

	a {
		padding-bottom: 0.2rem;
	}
}

.wrapper {
	width: 100%;
	max-width: 80rem;
	margin: 0 auto;
	position: relative;
	padding: 0 0.8rem;
}

.toaster {
  display: none;
  background: $brand-color-alt;
  z-index:9999;
  position:fixed;
  top:0;
  width:100%;
  padding:8px;
  color: white;
  text-align:center;
  -webkit-box-shadow: 0 5px 8px 0 rgba(0,0,0,0.05);
  box-shadow: 0 5px 8px 0 rgba(0,0,0,0.05);
  font-weight: 400;

  .toaster__title {
    display:block;
    font-size:14px;
  }

  .toaster__content {
    font-size:14px;
  }

  &.no {
    background: tomato;
    color: white;
  }

  &.thanks {
    background: $brand-color-alt !important;
    color: white;
  }

  @media only screen and (min-width:800px) {
    padding:16px;

    .toaster__title {
      display:inline;
    }

  }

}

.site-header {
	background: white;

	.wrapper {
		padding-left: 0;
		padding-right: 0;
	}
}

.site-brand {
	position: absolute;
	top: 0.8rem;
	left: 0.8rem;

	img {
		display: block;
		height: 6rem;
		transition: height 0.3s;

		@media only screen and (min-width:495px) {
			height: 9rem;
		}
	}
}

.site-nav {
	text-align: right;

	ul {
		padding-top: 60px;
		text-align: center;
		transition: padding 0.3s;

		@media only screen and (min-width:450px) {
			text-align: right;
			padding-top: 30px;
		}
	}

	li {
		display: inline-block;

		a {
			font-size: 1.2rem;
			padding: 3.2rem 0.4rem;
			display: block;
			text-transform: uppercase;
			border: 0;
			color: $brand-color-alt;
			transition: color 0.3s;
      font-weight: 600;

			@media only screen and (min-width:450px) {
				font-size: 1.3rem;
				padding: 3.2rem 0.8rem;
				letter-spacing: 0.5px;


				&:hover {
					color: darken($brand-color-alt, 10%);
				}
			}

		}
	}

}

.hr {
	height: 0.1rem;
	background: silver;
	width: 100%;
	max-width: 80rem;
	margin: 0 auto;
}

#hero {
	background: $brand-color;
	color: white;
	padding: 4.2rem 0;

	@media only screen and (min-width:450px) {
		padding: 12.8rem 0;
	}
}

#news {

	ul {
		list-style-type: disc;
		margin: 0.4em 0 0.4em 2.4rem;
	}

	li {
		font-size: 1.4rem;
		padding: 0.6rem 0;
	}

}

#contact {
	padding: 6.4rem 0;
	background: $brand-color;
	color: white;

	h2 {
		color: white;
	}

	label, input, textarea {
		display: block;
		width: 100%;
		border: 0;
		font-size: 1.5rem;

	}

	input, textarea {
		padding: 0.8rem;
		margin-top: 0.2rem;
		border: 2px solid $brand-color;
		// transition: border, 0.3s;

		&:focus {
			border-color: $brand-color-alt;
		}

		&.error {
			border-color: tomato;
		}
	}

	input {
		max-width: 40rem;
	}

	label {
		padding: 0.4rem 0;
	}

	textarea {
		height: 15rem;
	}

	button, .button {
		border: 0;
		background: $brand-color-alt;
		color: white;
		width: 100%;
		max-width: 31rem;
		font-size: 1.3rem;
		letter-spacing: 1px;
		text-transform: uppercase;
		padding: 1.2rem 0.8rem;
		cursor: pointer;
		font-weight: 600;
		display: inline-block;

		&:hover {
			background: darken($brand-color-alt, 10%);
		}

		&[disabled="disabled"], &[disabled="disabled"]:hover {
			opacity: 0.95;
			transform: scale(1);
			color: white;
			cursor: default;
			background-image: url(/assets/oval-loader.svg);
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 80% 80%;
			color: transparent;
		}

	}

	ul {
		font-size: 1.4rem;
		margin: 1.6rem 0;
	}

  a {
    color: white;

    &:hover {
  		color: white;
  		border-bottom-color: darken($brand-color-alt, 10%);
  	}
  }

}

#address {
	text-align: center;
	background: white url(/assets/map-bg.png) no-repeat center center;
	background-size: cover;

	ul {
		font-size: 1.4rem;
		margin: 1.6rem 0;
	}

	a {
		font-size: 1.4rem;
	}

}

.site-footer {
	font-size: 1.1rem;
	text-align: center;
	background: #f4f4f4;
	padding: 2.4rem 0;

	.credit {
		display: block;
		margin-top: 1.8rem;
	}
}
