// Resets & Helpers
textarea,
input,
select {
	outline:none; // remove webkit glow
}

* {
	box-sizing:border-box;
}

a,
a:hover,
a:focus {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-heading;
	margin: 0;
	font-weight: normal;
}

ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

address {
	font-style: normal;
}

.cf:after {
	content: "";
	display: table;
	clear: both;
}